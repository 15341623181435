// import moment from 'moment'
import moment from 'moment-timezone'

export function dateFormat(date) {
	if (date) {
		return moment(date).format('M-D-Y')
	}
}

export function timeFormat(date) {
	if (date != '') {
		return moment(date, 'HH:mm:ss').format('hh:mm A')
		// var new_date = moment.utc(utcTime).format('HH:mm:ss')
		// return moment.tz(new_date, 'hh:mm A', moment.tz.guess())
	} else {
		return ''
	}
}

// export function covUTCDate(d) {
//   console.log(moment.utc(d).format());
//   return moment().utc(d).format();
// }

export function covUTCTime(delivery_date, time) {
	// var d = moment(delivery_date).format('YYYY-MM-DD')
	var new_date = moment(delivery_date + ' ' + time).format()
	return moment.utc(new_date).format('HH:mm:ss')
}

export function covUTCDate(delivery_date, time) {
	// var d = moment(delivery_date).format('YYYY-MM-DD')
	var new_date = moment(delivery_date + ' ' + time).format()
	return moment.utc(new_date).format('YYYY-MM-DD')
}

export function covLocalTime(date, time) {
	if (date && time) {
		let utcDate = moment.utc(date + ' ' + time)
		return moment
			.utc(utcDate, 'YYYY-MM-DD HH:mm:ss')
			.tz(moment.tz.guess())
			.format('HH:mm:ss')
	}
}

export function covLocalDate(date, time) {
	if (date && time) {
		let utcDate = moment(date + ' ' + time).format('YYYY-MM-DD HH:mm:ss')
		return moment
			.utc(utcDate, 'YYYY-MM-DD HH:mm:ss')
			.tz(moment.tz.guess())
			.format('M-D-Y')
	}
}

export function localTimeFromDateTime(date_time) {
	if (date_time) {
		return moment
			.utc(date_time, 'YYYY-MM-DD HH:mm:ss')
			.tz(moment.tz.guess())
			.format('hh:mm A')
	}
}

export function localDateFromDateTime(date_time) {
	if (date_time) {
		return moment
			.utc(date_time, 'YYYY-MM-DD HH:mm:ss')
			.tz(moment.tz.guess())
			.format('M-D-Y')
	}
}

export function checkValidTimeOfDelivery(delivery_date, start_time) {
	var currentDatetime = moment().format('YYYY-MM-DD HH:mm:ss')
	var deliveryDate = moment(delivery_date).format('YYYY-MM-DD HH:mm:ss')

	return currentDatetime > deliveryDate ? true : false
}
